import React, {useState} from "react";
import {Divider, Tabs, Image, Modal} from "antd";
import {isMobile} from "react-device-detect";
import com1 from "../../assets/comercial/1.jpg";
import com2 from "../../assets/comercial/2.jpg";
import com3 from "../../assets/comercial/3.jpg";
import com4 from "../../assets/comercial/4.jpg";
import com5 from "../../assets/comercial/5.jpg";
import com6 from "../../assets/comercial/6.jpg";
import coc1 from "../../assets/cocina/1.jpg";
import coc2 from "../../assets/cocina/2.jpg";
import coc3 from "../../assets/cocina/3.jpg";
import coc4 from "../../assets/cocina/4.jpg";
import coc5 from "../../assets/cocina/5.jpg";
import coc6 from "../../assets/cocina/6.jpg";
import coc7 from "../../assets/cocina/7.jpg";
import coc8 from "../../assets/cocina/8.jpg";
import coc9 from "../../assets/cocina/9.jpg";
import coc10 from "../../assets/cocina/10.jpg";
import coc11 from "../../assets/cocina/11.jpg";
import coc12 from "../../assets/cocina/12.jpg";
import coc13 from "../../assets/cocina/13.jpg";
import coc14 from "../../assets/cocina/14.jpg";
import coc15 from "../../assets/cocina/15.jpg";
import coc16 from "../../assets/cocina/16.jpg";
import coc17 from "../../assets/cocina/17.jpg";
import coc18 from "../../assets/cocina/18.jpg";
import pu1 from "../../assets/puertas/1.jpg";
import pu2 from "../../assets/puertas/2.jpg";
import pu3 from "../../assets/puertas/3.jpg";
import pu4 from "../../assets/puertas/4.jpg";
import pu5 from "../../assets/puertas/5.jpg";
import pu6 from "../../assets/puertas/6.jpg";
import pu7 from "../../assets/puertas/7.jpg";
import pu8 from "../../assets/puertas/8.jpg";
import pu9 from "../../assets/puertas/9.jpg";
import pu10 from "../../assets/puertas/10.jpg";
import pu11 from "../../assets/puertas/11.jpg";
import pu12 from "../../assets/puertas/12.jpg";
import pu13 from "../../assets/puertas/13.jpg";
import pu14 from "../../assets/puertas/14.jpg";
import arm1 from "../../assets/armarios/1.jpg";
import arm2 from "../../assets/armarios/2.jpg";
import arm3 from "../../assets/armarios/3.jpg";
import arm4 from "../../assets/armarios/4.jpg";
import arm5 from "../../assets/armarios/5.jpg";
import arm6 from "../../assets/armarios/6.jpg";
import arm7 from "../../assets/armarios/7.jpg";
import arm8 from "../../assets/armarios/8.jpg";
import arm9 from "../../assets/armarios/9.jpg";
import arm10 from "../../assets/armarios/10.jpg";
import arm11 from "../../assets/armarios/10.jpg";
import arm12 from "../../assets/armarios/12.jpg";
import arm13 from "../../assets/armarios/13.jpg";
import arm14 from "../../assets/armarios/14.jpg";
import arm15 from "../../assets/armarios/15.jpg";
import arm16 from "../../assets/armarios/16.jpg";
import arm17 from "../../assets/armarios/17.jpg";
import arm18 from "../../assets/armarios/18.jpg";
import arm19 from "../../assets/armarios/19.jpg";
import arm30 from "../../assets/armarios/30.jpg";
import arm20 from "../../assets/armarios/20.jpg";
import arm22 from "../../assets/armarios/22.jpg";
import arm23 from "../../assets/armarios/23.jpg";
import arm24 from "../../assets/armarios/24.jpg";
import arm25 from "../../assets/armarios/25.jpg";
import arm26 from "../../assets/armarios/26.jpg";
import arm27 from "../../assets/armarios/27.jpg";
import arm28 from "../../assets/armarios/28.jpg";
import arm29 from "../../assets/armarios/29.jpg";
import arm31 from "../../assets/armarios/31.jpg";
import arm32 from "../../assets/armarios/32.jpg";
import arm33 from "../../assets/armarios/33.jpg";
import arm34 from "../../assets/armarios/34.jpg";
import med1 from "../../assets/Amedida/1.jpg";
import med2 from "../../assets/Amedida/2.jpg";
import med3 from "../../assets/Amedida/3.jpg";
import med3a from "../../assets/Amedida/3a.jpg";
import med4 from "../../assets/Amedida/4.jpg";
import med5 from "../../assets/Amedida/5.jpg";
import med6 from "../../assets/Amedida/6.jpg";
import med7 from "../../assets/Amedida/7.jpg";
import med8 from "../../assets/Amedida/8.jpg";
import med9 from "../../assets/Amedida/9.jpg";
import med10 from "../../assets/Amedida/10.jpg";
import med11 from "../../assets/Amedida/10.jpg";
import med12 from "../../assets/Amedida/12.jpg";
import med13 from "../../assets/Amedida/13.jpg";
import med14 from "../../assets/Amedida/14.jpg";
import med15 from "../../assets/Amedida/15.jpg";
import med17 from "../../assets/Amedida/17.jpg";
import med18 from "../../assets/Amedida/18.jpg";
import med19 from "../../assets/Amedida/19.jpg";
import med20 from "../../assets/Amedida/20.jpg";
import med21 from "../../assets/Amedida/21.jpg";
import med22 from "../../assets/Amedida/22.jpg";
import med23 from "../../assets/Amedida/23.jpg";
import med24 from "../../assets/Amedida/24.jpg";
import med25 from "../../assets/Amedida/25.jpg";
import med26 from "../../assets/Amedida/26.jpg";
import med27 from "../../assets/Amedida/27.jpg";
import med28 from "../../assets/Amedida/28.jpg";
import med29 from "../../assets/Amedida/29.jpg";




export default function Galeria(props){
    const { TabPane } = Tabs;
    const[modal,setModal]  = useState(false);
    const[modalImg,setModalImg]  = useState("");
    const coms = [com1,com2,com3,com4,com5, com6];
    const cocinas = [coc1,coc2,coc3,coc4,coc5,coc6,coc7,coc8,coc9,coc10,coc11,coc13,coc12,coc14,coc16,coc17,coc15,coc18];
    const puertas = [pu1,pu2,pu3,pu4,pu5,pu6,pu7,pu8,pu12,pu10,pu11,pu14,pu13,pu9];
    const armarios = [arm1,arm2,arm3,arm4,arm5,arm6,arm7,arm8,arm9,arm10,arm11,arm13,arm12,arm34,arm16,arm17,arm15,arm18,arm19,arm20,arm22,arm23,arm24,arm25,arm26,arm27,arm28,arm29,arm30,arm31,arm32,arm33,arm14];
    const medida = [ med1, med2, med6, med3a, med4, med5, med3, med7, med8, med9, med10, med11, med12, med13, med14, med15, med17, med18, med19, med20, med21, med22, med23,med24, med25, med26, med29, med28,med27];

    return(
        <div className="galeria">
            <Divider id="galeria">Galería</Divider>
            <Tabs defaultActiveKey="1" centered type="card" size={isMobile?"small":"large"}>
              <TabPane tab={<h2>Cocinas</h2>} key="1">
              <div className="imagenes">
                    <p>
                      La cocina es el corazón de tu hogar , por ello queremos hacer realidad su cocina, esa que siempre ha querido para que su hogar, por fin tenga ese espacio útil que podrán disfrutar usted y los suyos .
                      Sea cual sea la visión de la cocina que quiere, de diseño actual o con un diseño más tradicional, la crearemos para usted
                      Adaptada a las nuevas necesidades y a los nuevos diseños, una cocina eficiente, sin perder esa nota personal y de estilo, una cocina creada especialmente para usted. 
                      Piense, como sería su cocina y hablemos de cómo hacerla.
                    </p>
                        {isMobile?
                        <>
                            {cocinas.map(c=><img alt="Cargando..." src={c} className="img_galeria_mobile" onClick={()=>{setModal(true); setModalImg(c)}}/>)}
                        </>
                        :
                        <>
                            {cocinas.map((c,i)=><Image alt="Cargando..." src={c} className="img_galeria_desktop" key={i}/>)}
                        </>
                        }
                </div>
              </TabPane>
              <TabPane tab={<h2>Puertas</h2>} key="2">
                <p>
                  Disponemos del tipo de puerta que está buscando: lacada, en chapa natural, barnizada, clásica, rústica, lisa, fresada, de decoración, sintética, de una o dos hojas, acristalada... cuéntenos lo que necesita y le asesoraremos.
                </p>
                {isMobile?
                        <>
                            {puertas.map(c=><img alt="Cargando..." src={c} className="img_galeria_mobile" onClick={()=>{setModal(true); setModalImg(c)}}/>)}
                        </>
                        :
                        <>
                            {puertas.map((c,i)=><Image alt="Cargando..." src={c} className="img_galeria_desktop" key={i}/>)}
                        </>
                }
              </TabPane>
              <TabPane tab={<h2>Armarios</h2>} key="3">
                <p>
                  Imagine cómo será su armario, sus líneas, complementos y formas, cuéntenoslo y lo haremos realidad.
                  Tener un armario dentro de un espacio es lo ideal, pero ese armario no debe desentonar con el resto del habitáculo, todo lo contrario, debe fundirse con toda la composición de la estancia.
                </p>
                {isMobile?
                        <>
                            {armarios.map(c=><img alt="Cargando..." src={c} className="img_galeria_mobile" onClick={()=>{setModal(true); setModalImg(c)}}/>)}
                        </>
                        :
                        <>
                            {armarios.map((c,i)=><Image alt="Cargando..." src={c} className="img_galeria_desktop" key={i}/>)}
                        </>
                }
              </TabPane>
              <TabPane tab={<h2>Comercial</h2>} key="4">
                <div className="imagenes">
                    <p>Muebles comerciales para cualquier tipo de negocio.</p>
                        {isMobile?
                        <>
                            {coms.map(c=><img alt="Cargando..." src={c} className="img_galeria_mobile" onClick={()=>{setModal(true); setModalImg(c)}}/>)}
                        </>
                        :
                        <>
                            {coms.map((c,i)=><Image alt="Cargando..." src={c} className="img_galeria_desktop" key={i}/>)}
                        </>
                        }
                </div>
              </TabPane>
              <TabPane tab={<h2>A medida</h2>} key="5">
              <p>
                Sólo tienes que pedírnoslo. Un mueble para cada necesidad, una oportunidad de brindar espacios diferentes aportando diseño y funcionalidad a tu vivienda.
                En Fusteria Cami nou nos dedicamos a la carpintería de madera a medida, vestidores, frentes e interiores de armarios, puertas y baños.
                Encuentra en Fusteria Cami Nou  una empresa de confianza con una larga trayectoria, capaz de desarrollar ideas y proyectos de calidad.
              </p>
              {isMobile?
                        <>
                            {medida.map(c=><img alt="Cargando..." src={c} className="img_galeria_mobile" onClick={()=>{setModal(true); setModalImg(c)}}/>)}
                        </>
                        :
                        <>
                            {medida.map((c,i)=><Image alt="Cargando..." src={c} className="img_galeria_desktop" key={i}/>)}
                        </>
                }
              </TabPane>
            </Tabs>
            {modal?<Modal /*title="Basic Modal"*/ visible={modal} closable={true} footer={false} onCancel={()=>setModal(false)} width="96vw">
              <img width={"100%"} alt="cargando..." src={modalImg} onClick={()=>setModal(false)}/>
            </Modal>:""}
        </div>
    )
}