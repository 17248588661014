import React from "react";
import {Divider} from "antd";
import Banner from "../../assets/banner.jpg";

export default function Home(props){

    return(
        <div className="home">
            <img alt="banner" src={Banner} style={{width:"100%"}}/>
            <Divider id="inicio">Inicio</Divider>
            <p>Fusteria Cami Nou es una empresa con mas de 140 años de experiencia en el sector desde 1880, nuestra experiencia es nuestro aval y garantía para ofrecerte un acabado  y diseño excepcional .</p>
            <p>Nos encargamos de realizar todo tipo de mobiliario para el hogar y para el lugar que nuestros clientes nos sugieran. Somos especialistas en muebles a medida, armarios, cocinas y puertas.</p>
            
        </div>
    )
}