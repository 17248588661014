import React from "react";
import {Row, Col} from "antd";
import {HomeOutlined,ContactsOutlined, PictureOutlined} from "@ant-design/icons";
import {isMobile} from "react-device-detect";

export default function TopMenu(){

    return (
        
        <Row gutter={24} className="top_menu">
            <Col span={isMobile?0:4}></Col>
            <Col span={isMobile?24:16}>
                <div className="top_menu_items">
                    <span onClick={()=>{
                            let ini = document.getElementById("inicio");
                            ini.scrollIntoView({ behavior: 'smooth' });
                        }}
                        className="span_menu"
                    >
                        <HomeOutlined/>
                        Inicio
                    </span>
                    <span onClick={()=>{
                            let ini = document.getElementById("galeria");
                            ini.scrollIntoView({ behavior: 'smooth' });
                        }}
                        className="span_menu"
                    >
                        <PictureOutlined/>
                        Galería
                    </span>
                    <span onClick={()=>{
                            let ini = document.getElementById("contacto");
                            ini.scrollIntoView({ behavior: 'smooth' });
                        }}
                        className="span_menu"
                    >
                        <ContactsOutlined/>
                        Contacto
                    </span>
                </div>
            </Col>
            <Col span={isMobile?0:4}></Col>
        </Row>
    );
}