import React from 'react';
import { Layout, Menu } from 'antd';
import TopMenu from "./components/TopMenu";
import Contenido from "./pages/Contenido";
import {isMobile} from "react-device-detect";
import './App.scss';
import './styles/desktop.scss';
import './styles/mobile.scss';

function App() {
  const { Header, Content } = Layout;

  return (
    <div className="App">
      <Layout style={{height:"100%"}}>
        <Header className={isMobile?"mobile_layout_header":"desktop_layout_header"}>
          <Menu
            theme="dark"
            mode="horizontal"
          >
            <TopMenu/>
          </Menu>
        </Header>
        <Content className={isMobile?"mobile_layout_content":"desktop_layout_content"}>
          <Contenido/>
        </Content>
      </Layout>
      
    </div>
  );
}

export default App;