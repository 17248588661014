import React,{useState} from "react";
import {Image, Modal} from "antd";
import {isMobile} from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css';
import img1 from "../../assets/1.jpg"
import img11 from "../../assets/11.jpg"
import img2 from "../../assets/2.jpg"
import img22 from "../../assets/22.jpg"
import img3 from "../../assets/3.jpg"
import img33 from "../../assets/33.jpg"

export default function Preview(props){
    const imgs = [img1, img11,img2,img22,img3,img33];
    const[modal,setModal]  = useState(false);
    const[modalImg,setModalImg]  = useState("");
    return(
        <div className="preview">
            <Swiper
              slidesPerView={isMobile?2:3}
              spaceBetween={30}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false
              }}
              pagination={{
                clickable: true
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="carousel"
            >
              {isMobile?
                imgs.map(i=>
                       <SwiperSlide><img height={isMobile?"180px":"280px"} alt="cargando..." src={i} onClick={()=>{setModal(true); setModalImg(i)}}/></SwiperSlide>
                )
                :
                imgs.map((i,index)=>
                    <SwiperSlide><Image height={isMobile?"180px":"280px"} alt="cargando..." src={i} key={index}/></SwiperSlide>
                )
              }
            </Swiper>

            {modal?<Modal /*title="Basic Modal"*/ visible={modal} closable={true} footer={false} onCancel={()=>setModal(false)}>
              <img width={"100%"} alt="cargando..." src={modalImg} onClick={()=>setModal(false)}/>
            </Modal>:""}
        </div>
    )
}