import React from "react";
import {Row, Col} from "antd";
import {isMobile} from "react-device-detect";
import Home from "../components/Home";
import Galeria from "../components/Galeria";
import Contacto from "../components/Contacto";
import Preview from "../components/Preview";

export default function Contenido(props){

    return(
        <div className="contenido">
            {isMobile?
            <>
                <Preview/>
                <Home/>
                <Galeria/>
                <Contacto/>
            </>
            :
            <Row gutter={24}>
                <Col span={4} className="lateral_izq"><div className="grad_izq"></div></Col>
                <Col span={16}>
                    <Preview/>
                    <Home/>
                    <Galeria/>
                    <Contacto/>
                </Col>
                <Col span={4} className="lateral_dcha"><div className="grad_dcha"></div></Col>
            </Row>
            }
            
        </div>
    )
}