import React, {useState} from "react";
import {Divider, Image, Button,Modal} from "antd";
import {MailOutlined, PhoneOutlined,ShopOutlined} from "@ant-design/icons";
import {isMobile} from "react-device-detect";
import Mapa from "../../assets/Mapa.JPG";

export default function Contacto(props){
    const[modal,setModal]  = useState(false);
    const[modalImg,setModalImg]  = useState("");

    return(
        <div className="contacto">
            <Divider id="contacto">Contacto</Divider>
            {isMobile?
                <p><a href="tel:+34676558776" style={{fontSize:"1.1em", fontWeight:"bold"}}><PhoneOutlined style={{fontSize:"1.1em", paddingRight:10}}/>676558776</a></p>
            :
                <p style={{fontSize:"1.1em", fontWeight:"bold"}}><PhoneOutlined style={{fontSize:"1.1em", paddingRight:10}}/>676558776</p>
            }
            <p><a href = "mailto:fusteriacaminou@hotmail.com" style={{fontSize:"1.1em", fontWeight:"bold"}}><MailOutlined style={{fontSize:"1.1em", paddingRight:10}}/>fusteriacaminou@hotmail.com</a></p>
            <p style={{fontSize:"1.1em", fontWeight:"bold"}}><ShopOutlined style={{fontSize:"1.1em", paddingRight:10}}/>Av/ Blasco Ibañez, 29, Massanassa</p>
            <Button onClick={()=>{
                        window.open("https://www.google.es/maps/place/Carrer+de+Blasco+Ib%C3%A1%C3%B1ez,+29,+46470+Massanassa,+Val%C3%A8ncia/@39.4097829,-0.4012925,19z/data=!4m5!3m4!1s0xd604e9ba75f8317:0x1fe7636141f4c6a4!8m2!3d39.4097829!4d-0.4008323",'_blank');
            }}
            style={{background:"#050505", color:"#f0f0f0", fontWeight:"bold", marginBottom:12}}
            >
                Cómo llegar
            </Button>
            <div style={{display:"flex", justifyContent:"center", marginBottom:40}}>
                {isMobile?
                    <img alt="mapa" src={Mapa} style={{width:"90%"}} onClick={()=>{setModal(true); setModalImg(Mapa)}}/>
                    :
                    <Image alt="mapa" src={Mapa} style={{width:"75%"}}/>
                }
            </div>
            {modal?<Modal /*title="Basic Modal"*/ visible={modal} closable={true} footer={false} onCancel={()=>setModal(false)}>
              <img width={"100%"} alt="cargando..." src={modalImg} onClick={()=>setModal(false)}/>
            </Modal>:""}
        </div>
    )
}